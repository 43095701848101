import { useEffect } from 'react';

import useScript from '../hooks/use-script/useScript';

import mainImage from '../../../static/images/bascule/MainHorseImage.jpg';
import mainImage4 from '../../../static/images/bascule/MainHorse-4.jpg';
import Slide from './slide/Slide';

declare const jQuery: any;

const Slider: React.FC = () => {
  useScript(process.env.PUBLIC_URL + '/js/jquery.min.js');
  useScript(process.env.PUBLIC_URL + '/js/jquery.prettyPhoto.js');
  useScript(process.env.PUBLIC_URL + '/revolution/js/jquery.themepunch.tools.min.js');
  useScript(process.env.PUBLIC_URL + '/revolution/js/jquery.themepunch.revolution.min.js');
  const sliderStatus = useScript(process.env.PUBLIC_URL + '/revolution/js/slider.js');

  useEffect(() => {
    if (sliderStatus === 'ready') {
      jQuery.fn.showSlider();
    }
  }, [sliderStatus]);

  return (
    <div
      id="rev_slider_4_2_wrapper"
      className="rev_slider_wrapper fullwidthbanner-container slide-overlay"
      data-alias="classic4export"
      data-source="gallery"
    >
      <div
        id="rev_slider_4_2"
        className="rev_slider fullwidthabanner rev_slider_4_2_height"
        data-version="5.4.8.1"
      >
        <ul>
          <Slide
            image={mainImage}
            // text1=""
            // text2=""
            text1="Bascule Underwriting"
            text2=""
            buttonText="Contact Us"
          />
          <Slide
            image={mainImage4}
            // text1=""
            // text2=""
            text1="Bascule Underwriting"
            text2="Has Got You Covered"
            buttonText="Contact Us"
          />
        </ul>
      </div>
    </div>
  );
};

export default Slider;
