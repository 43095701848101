import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';

import styles from './ExtraDetailsModal.module.css';

Modal.setAppElement('#root');

const ExtraDetailsModal = (props) => {
  const { invoiceDetails, setInvoiceDetails } = props;

  const _closeModal = () => setInvoiceDetails();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return invoiceDetails ? (
    <Modal
      isOpen={!!invoiceDetails}
      onRequestClose={_closeModal}
      style={customStyles}
      contentLabel="Invoice Details"
    >
      <div className="modal-header">
        <h5 className="modal-title">Invoice #{invoiceDetails['d__InvoiceNumber']}</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={_closeModal}
        >
          <FaTimes color="red" size="1.2rem" />
        </button>
      </div>
      <div className="modal-body">
        <table className={`table table-striped table-responsive-sm ${styles.tableNoBorder}`}>
          <thead>
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {invoiceDetails['invoiceItems']?.map((item) => (
              <tr key={item.recordId}>
                <td className="align-middle">
                  {item['Invoice_03_INVOICEITEM_IDInvoice::dc__ItemName']}
                </td>
                <td className="align-middle">
                  {item['Invoice_03_INVOICEITEM_IDInvoice::d__description']}
                </td>
                <td className="align-middle">
                  {'$ ' +
                    (parseFloat(item['Invoice_03_INVOICEITEM_IDInvoice::d__amount']) || 0).toFixed(
                      2,
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default ExtraDetailsModal;
