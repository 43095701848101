import classNames from 'classnames';
import { TiHomeOutline } from 'react-icons/ti';
import Label from '../../shared/components/label/Label';

import PageTitle from '../../shared/components/page-title/PageTitle';
import ClaimsForm from './claims-form/ClaimsForm';
import styles from './ClaimsTemplate.module.css';

export interface ClaimsTemplateProps {
  title?: string;
  menuLabel?: string;
}

const defaultProps = {
  title: 'Claims',
  menuLabel: 'Claims',
};

const ClaimsTemplate: React.FC<ClaimsTemplateProps> = (props) => {
  const { title = defaultProps.title, menuLabel = defaultProps.menuLabel } = props;

  return (
    <>
      <PageTitle
        title={title}
        path={{
          title: 'Home',
          Icon: TiHomeOutline,
          linksTo: '/',
          child: {
            title: menuLabel,
          },
        }}
      />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            <p className={styles.paragraph}>
              Please provide Immediate Notice of all accidents, illness, injury, and / or death to
              the insured animal(s).
            </p>

            <p className={styles.paragraph}>
              Call <a href="tel:+18774745006">+1 877 474 5006</a> and press 1.
            </p>

            <div className={classNames(styles.procedures, styles.spacing)}>
              <h2 className={styles.procedures__title}>Animal Loss Procedures</h2>
              <p className={styles.procedures__listTitle}>DO</p>
              <ul className={styles.list}>
                <li>Report loss immediately</li>
                <li>Contact your veterinarian</li>
                <li>Make arrangements for an autopsy</li>
                <li>Call police in cases of theft, shooting, or vehicular involvement.</li>
              </ul>
              <br />
              <p className={styles.procedures__listTitle}>DON'T</p>
              <ul className={styles.list}>
                <li>Have animal destroyed without Bascule’s consent</li>
                <li>Have animal removed prior to having autopsy completed</li>
                <li>Neglect a police report if needed.</li>
              </ul>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <Label warning>
                <p className={styles.important__title}>IMPORTANT</p>
                <p>
                  Failure to comply with the policy terms and conditions could jeopardize your
                  coverage. Please call these procedures to the attention of anyone having Care,
                  Custody and Control of the insured animal.
                </p>
              </Label>
            </div>
            <div className={styles.claimsForm}>
              <ClaimsForm />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

ClaimsTemplate.defaultProps = defaultProps;

export default ClaimsTemplate;
