import classNames from 'classnames';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { postContactForm } from '../../../api/client/api';
import ProtectedSubmit from '../../../shared/components/form/ProtectedSubmit/ProtectedSubmit';
import { USState } from '../../../shared/enums/USState';

const ContactForm = () => {
  const { register, handleSubmit, watch, reset } = useForm();
  const recaptchaRef: any = useRef();

  const { mutateAsync: sendContactForm, isLoading: isContactLoading } = useMutation((formData) =>
    postContactForm(formData),
  );

  const { email, phone } = watch(['email', 'phone']);

  const onSubmit = async (data) => {
    await recaptchaRef.current.executeAsync();
    try {
      await sendContactForm(data);
      toast.success('Thank you for contacting us', { toastId: 'success-contact' });
      reset();
      recaptchaRef.current.reset();
    } catch (error) {
      toast.error('An error occurred. Please try again later.', {
        toastId: 'error-contact',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  return (
    <div className="widget" style={{ flex: 1 }}>
      <h3 className="widget-title">Contact Form</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="name" className="required">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className="form-control"
            ref={register({ required: true })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className={classNames({ required: !phone })}>
            E-mail
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            ref={register({ required: !phone })}
            required={!phone}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone" className={classNames({ required: !email })}>
            Phone
          </label>
          <input
            id="phone"
            name="phone"
            type="tel"
            className="form-control"
            ref={register({ required: !email })}
            required={!email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="state">State</label>
          <select id="state" name="state" className="form-control" ref={register()}>
            <option value=""></option>

            {Object.keys(USState).map((state) => (
              <option key={state} value={USState[state]}>
                {USState[state]}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Policy Number</label>

          <input
            id="policyNumber"
            name="policyNumber"
            className="form-control"
            type="text"
            ref={register()}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject" className="required">
            Subject
          </label>
          <input
            id="subject"
            name="subject"
            className="form-control"
            type="text"
            ref={register({ required: true })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message" className="required">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            rows={8}
            ref={register({ required: true })}
            required
          />
        </div>
        <ProtectedSubmit isLoading={isContactLoading} ref={recaptchaRef} />
      </form>
    </div>
  );
};

export default ContactForm;
