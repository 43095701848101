import { UseQueryOptions, useQuery } from 'react-query';

import { fetchPostsPaginated } from '../../client/api';

const usePostsQuery = (page: number, options: UseQueryOptions = {}) => {
  return useQuery<any, any>(['posts', page], () => fetchPostsPaginated(page), {
    keepPreviousData: true,
    staleTime: 5 * 1000 * 60,
    ...options,
  });
};

export default usePostsQuery;
