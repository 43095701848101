import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaRegClock,
  FaRegEnvelope,
  FaPhoneAlt,
} from 'react-icons/fa';
import SocialIcon from './nav-social-icons/SocialIcon';
import NavInfo from './nav-info/NavInfo';

export interface TopHeaderProps {
  firstPageInfo: any[];
}

const TopHeader: React.FC<TopHeaderProps> = (props) => {
  const { firstPageInfo } = props;
  const iconStyle = {
    marginBottom: 1,
    marginRight: 9,
  };

  const hoursInfo = firstPageInfo.find((info) => info.slug === 'OPEN_HOURS');
  const emailInfo = firstPageInfo.find((info) => info.slug === 'EMAIL');
  const phoneInfo = firstPageInfo.find((info) => info.slug === 'PHONE');

  return (
    <div className="ttm-topbar-wrapper ttm-textcolor-white clearfix">
      <div className="container">
        <div className="ttm-topbar-content">
          <ul className="top-contact text-left">
            <NavInfo
              label={hoursInfo?.option_name}
              value={hoursInfo?.value}
              type="TEXT"
              icon={<FaRegClock style={iconStyle} />}
            />
          </ul>
          <div className="topbar-right text-right">
            <ul className="top-contact">
              <NavInfo
                value={emailInfo?.value}
                type="MAIL"
                icon={<FaRegEnvelope style={iconStyle} />}
              />
              <NavInfo
                value={phoneInfo?.value}
                type="PHONE"
                icon={<FaPhoneAlt style={iconStyle} />}
              />
            </ul>
            <div className="ttm-social-links-wrapper list-inline">
              <ul className="social-icons">
                {/* <SocialIcon linksTo="#" icon={<FaFacebook />} /> */}
                {/* <SocialIcon linksTo="#" icon={<FaTwitter />} /> */}
                <SocialIcon
                  linksTo="https://www.linkedin.com/company/basculeuw/about/"
                  icon={<FaLinkedin />}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
