import { useHistory, useParams } from 'react-router-dom';

import usePostQuery from '../../api/hooks/use-post-query/usePostQuery';
import ErrorTemplate from '../../templates/error/ErrorTemplate';
import PostTemplate from '../../templates/post/PostTemplate';

const PostScreen = () => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();

  const { isSuccess, data = { post: {} }, isError } = usePostQuery(slug);

  if (!data.post) {
    history.replace('/');

    return null;
  }

  const imagePath = data.post.files?.find((file) => file.featured)?.path;

  if (isError) {
    return <ErrorTemplate />;
  }

  return isSuccess ? (
    <PostTemplate
      nextPostSlug={data.nextPostSlug}
      previousPostSlug={data.previousPostSlug}
      post={{
        title: data.post.title,
        slug: data.post.slug,
        date: new Date(data.post.created_at),
        body: data.post.contents_html?.replaceAll(
          'storage/uploads/',
          `${process.env.REACT_APP_STATIC_URL}/uploads/`,
        ),
        image: imagePath ? `${process.env.REACT_APP_STATIC_URL}/${imagePath}` : '',
      }}
    />
  ) : (
    <></>
  );
};

export default PostScreen;
