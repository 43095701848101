import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export interface PostHeaderProps {
  date: Date;
  id: string;
  postTitle: string;
}

const PostHeader: React.FC<PostHeaderProps> = (props) => {
  const { date, id, postTitle } = props;

  return (
    <>
      <div className="ttm-portfolio-title mt-40">
        <h4 className="ttm-pf-detailbox-title">
          <Link to={'/news/post/' + id}>{postTitle}</Link>
        </h4>
      </div>

      <div className="featured-content featured-content-post">
        <div className="post-meta">
          <span className="ttm-meta-line">{format(date, 'MMMM dd, yyyy')}</span>
        </div>
        <div className="separator">
          <div className="sep-line solid mt-10 mb-20"></div>
        </div>
      </div>
    </>
  );
};

export default PostHeader;
