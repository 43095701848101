export interface SocialIconProps {
  linksTo: string;
  icon: React.ReactElement;
}

const SocialIcon: React.FC<SocialIconProps> = (props) => {
  const { linksTo, icon } = props;

  return (
    <li>
      <a href={linksTo}>{icon}</a>
    </li>
  );
};

export default SocialIcon;
