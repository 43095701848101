import CommonButton from '../../common-button/CommonButton';
import PostHeader from '../post-header/PostHeader';
import PostImage from '../post-image/PostImage';

export interface PostPreviewProps {
  post: any;
}

const PostPreview: React.FC<PostPreviewProps> = (props) => {
  const { post } = props;

  return (
    <div className="row mt-80 res-991-mt-30">
      <PostImage image={post.image} linksTo={'/news/post/' + post.id} />
      <div className="col-lg-12">
        <PostHeader date={post.date} id={post.id} postTitle={post.title} />
        <div dangerouslySetInnerHTML={{ __html: post.content }}></div>

        <div className="mb-20">
          <CommonButton buttonText="READ MORE" linksTo={'/news/post/' + post.id} />
        </div>
      </div>
    </div>
  );
};

export default PostPreview;
