import CustomGoogleMap from './custom-google-map/CustomGoogleMap';
import styles from './LocationItem.module.css';

export interface LocationItemProps {
  lat: number;
  lng: number;
  imageSrc: string;
  showMap: boolean;
  alt?: string;
  children?: any;
}

const LocationItem: React.FC<LocationItemProps> = (props) => {
  const { lat, lng, imageSrc, showMap, alt, children } = props;

  return (
    <div className={styles.locationItem}>
      <div className={styles.locationItem__Details}>
        <img src={imageSrc} alt={alt} />
        <div className={styles.locationItem__Content}>{children}</div>
      </div>
      <div className={styles.locationItem__Map}>
        {showMap ? <CustomGoogleMap lat={lat} lng={lng} /> : ''}
      </div>
    </div>
  );
};

export default LocationItem;
