import { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Label from '../../shared/components/label/Label';

const pLinkSingle =
  process.env.REACT_APP_PAYMENTS_ENV === 'testing'
    ? 'https://test.simply-easier-payments.com/PaymentApp/Provider/insurance/fp/Payment.faces'
    : 'https://www.simply-easier-payments.com/PaymentApp/Provider/insurance/fp/Payment.faces';

const uNameSingle =
  process.env.REACT_APP_PAYMENTS_ENV === 'testing' ? 'OPBQRERG5B' : '2/rZV4V1w65Y/FMC+mhrCQ==';

const uPassSingle = process.env.REACT_APP_PAYMENTS_ENV === 'testing' ? '4k3l1!@k' : '[B@37a0c4b9';

const OneTimePaymentScreen = () => {
  const { register, trigger, errors } = useForm({ mode: 'onChange' });

  const [accountDescription, setAccountDescription] = useState('');

  const onSubmit = async () => {
    const validate = await trigger();

    if (
      validate &&
      (accountDescription === 'BAS' ||
        accountDescription === 'FRM' ||
        accountDescription === 'ACCEL')
    ) {
      const form: HTMLFormElement | null = document?.getElementById(
        'paymentForm',
      ) as HTMLFormElement;
      form?.submit();
    } else {
      toast.warning('Please check your input.', {
        toastId: 'one-time-payment-warning',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  const _isFarmPolicy = (policy: string) =>
    ['FRM', 'FRX', 'MXA', 'AS1', 'SVC', 'USC', 'EQFL', 'EQPKG'].some((key) =>
      policy.startsWith(key),
    );

  const _policyChangeHandler = (e) => {
    const policy: string = e.target.value.toUpperCase();

    if (policy.startsWith('BAS') || policy.startsWith('N')) {
      setAccountDescription('BAS');
    } else if (_isFarmPolicy(policy)) {
      setAccountDescription('FRM');
    } else if (policy.startsWith('S')) {
      setAccountDescription('ACCEL');
    }
    e.target.value = policy;
  };

  return (
    <div className="site-main ttm-page-title-row-bascule fixMobile">
      <section className="ttm-row single-project-section clearfix">
        <div className="container">
          <h3>Farm / Liability / One-Time Payment Portal</h3>
          <Label warning>
            <p className="font-weight-bold text-center">
              If you want to make a payment without logging in, please use the form below.
            </p>
            <p>
              Please note: If paying by credit card there is a 3.25% service fee. There is NO fee if
              you pay by e-check (ACH).
            </p>
            <p>
              Default is credit card payment. If you wish to change please select ACH (E-check) on
              the next screen.
            </p>
          </Label>
          <form id="paymentForm" method="POST" action={pLinkSingle} className="mt-4">
            <input type="hidden" name="app_user" value={uNameSingle} />
            <input type="hidden" name="app_password" value={uPassSingle} />
            <input type="hidden" name="payment_min" value={1} />
            <input type="hidden" name="payment_max" value={25000} />
            <input type="hidden" name="account_description_selection" value={accountDescription} />
            <div className="form-group">
              <label htmlFor="policy_number" className="required">
                Policy Number
              </label>
              <input
                type="text"
                id="policy_number"
                name="policy_number"
                className={classNames('form-control', {
                  'is-invalid': errors.policy_number,
                })}
                onChange={_policyChangeHandler}
                ref={register({
                  required: 'The field is required',
                  validate: {
                    policyType: (value: string) =>
                      value.toUpperCase().startsWith('BAS') ||
                      value.toUpperCase().startsWith('N') ||
                      _isFarmPolicy(value) ||
                      value.toUpperCase().startsWith('S'),
                  },
                })}
                required
              />
              {errors.policy_number && errors.policy_number.message && (
                <div className="invalid-feedback">{errors.policy_number.message}</div>
              )}
              {errors.policy_number && errors.policy_number.type === 'policyType' && (
                <div className="invalid-feedback">The policy is incorrect</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="amount" className="required">
                Amount
              </label>
              <input
                type="number"
                id="amount"
                name="payment_amount"
                step={0.1}
                className={classNames('form-control', {
                  'is-invalid': errors.payment_amount,
                })}
                ref={register({
                  required: 'The field is required',
                  min: { value: 1, message: 'The amount should be at least 1' },
                  max: { value: 25000, message: 'The amount should be lower than 25000' },
                })}
                required
              />
              {errors.payment_amount && (
                <div className="invalid-feedback">{errors.payment_amount.message}</div>
              )}
            </div>
            <div className="pb-3">
              <Label warning>
                Please note that by clicking on the submit button you will be sent to Simply Easier
                Payments to complete your payment. This is normal and provides you with an elevated
                level of security and clear information on relevant fees.
                <br />
                <br />* Fields with an asterisk are required.
              </Label>
            </div>
            <div>
              <button
                type="button"
                onClick={onSubmit}
                className="ttm-btn
            ttm-btn-size-xs
            ttm-btn-shape-square
            ttm-btn-style-fill
            ttm-btn-bgcolor-skincolor
            ttm-btn-color-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default OneTimePaymentScreen;
