import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';
import { TiHomeOutline } from 'react-icons/ti';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

import PostImage from '../../shared/components/post/post-image/PostImage';
import CommonButton from '../../shared/components/common-button/CommonButton';
import SocialIconsRound from '../../shared/components/social-icon-round/SocialIconsRound';
import PageTitle from '../../shared/components/page-title/PageTitle';

export interface PostTemplateProps {
  post: any;
  nextPostSlug?: string;
  previousPostSlug?: string;
}

const PostTemplate: React.FC<PostTemplateProps> = (props) => {
  const { post, nextPostSlug, previousPostSlug } = props;

  const postUrl = `${window.location.origin}/news/post/${post.slug}`;

  return (
    <>
      <Helmet>
        <meta property="og:title" content={'Bascule: ' + post.title} />
        <meta property="og:url" content={postUrl} />
        <meta property="og:image" content={post.image} />
      </Helmet>
      <PageTitle
        title={post.title}
        postDate={format(post.date, 'MMMM dd, yyyy')}
        path={{
          title: 'Home',
          Icon: TiHomeOutline,
          linksTo: '/',
          child: {
            title: 'News',
            linksTo: '/news',
            child: {
              title: post.title,
            },
          },
        }}
      />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            <div className="row mt-60 res-991-mt-30">
              <PostImage image={post.image} linksTo={'/news/post/' + post.slug} />
              <div
                className="col-lg-12 mt-20 main-content-editor"
                dangerouslySetInnerHTML={{ __html: post.body }}
              ></div>
            </div>
            <div className="row mt-30">
              <div className="col-lg-12">
                <div className="social-icons circle social-hover">
                  <div className="ttm-social-share-title d-inline-block">
                    <h5> Share It</h5>
                  </div>
                  <ul className="list-inline mb-0 d-inline-block">
                    <SocialIconsRound
                      linksTo={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}&title=${post.title}`}
                      icon={<FaFacebook />}
                    />
                    <SocialIconsRound
                      linksTo={`https://twitter.com/intent/tweet?original_referer=${postUrl}&text=${post.title}&url=${postUrl}`}
                      icon={<FaTwitter />}
                    />
                    <SocialIconsRound
                      linksTo={`https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`}
                      icon={<FaLinkedin />}
                    />
                  </ul>
                </div>
              </div>
            </div>
            <div className="separator">
              <div className="sep-line solid mt-30 mb-30" />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-nextprev-bottom-nav mb-20">
                  <nav className="navigation post-navigation">
                    <div className="nav-links d-flex justify-content-between">
                      {previousPostSlug ? (
                        <CommonButton
                          linksTo={`/news/post/${previousPostSlug}`}
                          className="ttm-btn ttm-btn-size-sm ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor"
                        >
                          <FaAngleDoubleLeft size={11} style={{ marginTop: -2 }} />
                          &nbsp; Previous
                        </CommonButton>
                      ) : (
                        <div />
                      )}
                      {nextPostSlug ? (
                        <CommonButton
                          linksTo={`/news/post/${nextPostSlug}`}
                          className="ttm-btn ttm-btn-size-sm ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor"
                        >
                          Next &nbsp;
                          <FaAngleDoubleRight size={11} style={{ marginTop: -2 }} />
                        </CommonButton>
                      ) : (
                        <div />
                      )}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PostTemplate;
