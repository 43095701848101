import SocialIconsRound from '../../../shared/components/social-icon-round/SocialIconsRound';
import FooterItemContainer from '../footer-item-container/FooterItemContainer';
import CommonButton from '../../../shared/components/common-button/CommonButton';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

export interface AboutOurBusinessItemProps {
  title?: string;
  text?: string;
}

const defaultProps = {
  title: 'About our business',
  text:
    'At Bascule, we bring deep “hands on” horse experience and specialize in all areas of bloodstock and equine insurance.',
};

const AboutOurBusinessItem: React.FC<AboutOurBusinessItemProps> = (props) => {
  const { title = defaultProps.title, text = defaultProps.text } = props;

  return (
    <FooterItemContainer className="widget widget_text  clearfix" label={title}>
      <div>
        <div className="textwidget widget-text" />
        {text}
        <br />
        <br />
        <div className="social-icons circle social-hover">
          <ul className="list-inline">
            {/* <SocialIconsRound linksTo="#" icon={<FaFacebook />} tooltip="Facebook" /> */}
            {/* <SocialIconsRound linksTo="#" icon={<FaTwitter />} tooltip="Twitter" /> */}
            <SocialIconsRound
              linksTo="https://www.linkedin.com/company/basculeuw/about/"
              icon={<FaLinkedin />}
              tooltip="Linkedin"
            />
          </ul>
        </div>
        <br />
        <div className="mb-20">
          <CommonButton linksTo="/about" buttonText={'READ MORE'} />
        </div>
      </div>
    </FooterItemContainer>
  );
};

// Specifies the default values for props:
AboutOurBusinessItem.defaultProps = defaultProps;

export default AboutOurBusinessItem;
