import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import Header from '../components/header/Header';
import Label from '../../../shared/components/label/Label';
import { PaymentDispatchContext, PaymentStateContext } from '../context/PaymentContext';
import { postPaymentLogin } from '../../../api/client/api';

const LoginPagePayment = (props) => {
  const { mutateAsync: sendLoginForm, isLoading: isFormLoading } = useMutation((formData: any) =>
    postPaymentLogin(formData),
  );

  const { register, handleSubmit, setValue } = useForm();

  const paymentState = useContext(PaymentStateContext);
  const setPaymentState = useContext(PaymentDispatchContext);

  useEffect(() => {
    setValue('contact_email', paymentState.data.contact_email);
  }, [setValue, paymentState.data.contact_email]);

  const onSubmit = async (data) => {
    try {
      const response = await sendLoginForm({
        contact_email: paymentState.data.contact_email?.trim(),
        password: data.password?.trim(),
      });
      if (response.hasOwnProperty('success') && !response.success) {
        toast.error(
          response.message || 'The credentials you provided are not correct. Please try again.',
          { closeButton: true, autoClose: false, position: 'bottom-center' },
        );
      } else {
        sessionStorage.setItem('payment_user', JSON.stringify(response));
        setPaymentState({
          data: { reset: false },
          type: 'invoiceData',
        });
      }
    } catch (error) {
      toast.error('An error has occurred. Please try again later or contact with us.', {
        toastId: 'login-page-error',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  const _handleForgotPassword = () => {
    setPaymentState({
      type: 'forgotPassword',
    });
  };

  return (
    <>
      <Header subtitle="Please enter your password" />
      <Label>
        In order to make payments, see your policies, invoices and payments history please log in by
        entering your password
      </Label>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginTop: '2.5rem', marginBottom: '2.5rem' }}
      >
        {paymentState.data.reset && (
          <div className="alert alert-info" style={{ marginBottom: '0.5rem' }}>
            An email has been sent to you with your new password. Please use the new password to
            login below.
          </div>
        )}
        <fieldset disabled={isFormLoading}>
          <div className="form-group">
            <label htmlFor="contact_email" className="required">
              Contact Email
            </label>
            <input
              type="email"
              id="contact_email"
              name="contact_email"
              className="form-control"
              disabled
              ref={register({ required: true })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="required">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-control"
              ref={register({ required: true })}
              required
            />
          </div>
        </fieldset>
        <div>
          <button
            type="submit"
            disabled={isFormLoading}
            className="ttm-btn
            ttm-btn-size-xs
            ttm-btn-shape-square
            ttm-btn-style-fill
            ttm-btn-bgcolor-skincolor
            ttm-btn-color-white"
          >
            Submit
          </button>
        </div>
      </form>
      <div>
        <a
          href="#"
          onClick={() =>
            setPaymentState({
              data: {
                reset: false,
                contact_email: '',
              },
              type: '',
            })
          }
        >
          Go Back
        </a>
        <span> - </span>
        <a href="#" onClick={_handleForgotPassword}>
          Forgot password
        </a>
      </div>
    </>
  );
};

export default LoginPagePayment;
