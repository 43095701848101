import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import Header from '../components/header/Header';
import Label from '../../../shared/components/label/Label';
import { PaymentDispatchContext } from '../context/PaymentContext';

import { postPaymentCollectEmail } from '../../../api/client/api';
import { Link } from 'react-router-dom';
import PasswordCreateForm from '../password-creation/PasswordCreateForm';

const FirstPagePayment = () => {
  const {
    mutateAsync: sendCollectEmailForm,
    isLoading: isFormLoading,
  } = useMutation((formData: any) => postPaymentCollectEmail(formData));
  const { register, handleSubmit } = useForm();

  const dispatch = useContext(PaymentDispatchContext);

  const [isActivated, setIsActivated] = useState(false);

  const [error, setError] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);

  const onSubmit = async (data) => {
    try {
      setError(false);
      const response = await sendCollectEmailForm({
        contact_email: data.contact_email?.trim(),
      });

      switch (response.nextStep) {
        case 'userNotInCanter':
          return setError(true);
        case 'login':
          return dispatch({
            data: {
              contact_email: data.contact_email,
            },
            type: 'loginPage',
          });
        case 'createPassword':
          dispatch({
            data: {
              contact_email: data.contact_email,
            },
          });

          return setIsPasswordCreate(true);
        default:
          toast.error('An error occurred. Please try again later. Thank you!', {
            toastId: 'first-page-payment-error',
            closeButton: true,
            autoClose: false,
            position: 'bottom-center',
          });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later. Thank you!', {
        toastId: 'first-page-payment-error',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  return (
    <>
      <Header />
      <Label warning>
        {!isPasswordCreate && (
          <>
            <p>
              <em>
                To pay and/or to see your installments and payment history please enter the contact
                email you entered on your application to login below. If that does not work, OR if
                you want to make a one-time payment without logging in, please{' '}
                <strong>
                  <Link to="/payment/one-time">click here</Link>
                </strong>
                .
              </em>
            </p>

            <p className="font-weight-bold">
              To pay and/or to see your installments and payment history please enter the contact
              email you entered on your application.
            </p>
          </>
        )}
        {isPasswordCreate && (
          <>
            {!isActivated && (
              <p>
                Your account had not yet been registered into our system.
                <strong>
                  {' '}
                  Check your emails for the activation code. <br />
                </strong>
              </p>
            )}

            {isActivated && (
              <p>
                Please provide a strong password. <br />
                Passwords must have a length of at least 10 characters, at least one number and
                contain at least one capital letter.
                <br />
                Please don't use passwords that are easy to guess.
              </p>
            )}
          </>
        )}
      </Label>
      {error && (
        <div style={{ marginTop: '2rem' }}>
          <p style={{ fontSize: '18px' }}>Your Email was not found in our systems.</p>
          <Label warning>
            <span>
              In order to see your policies and payments history you must enter the contact email
              exactly as you gave it for your policy.
            </span>
            <br />
            <br />
            If the email you provided has changed or is no longer valid please call us at
            <a href="tel:+1877-474-5006" className="text-cyan">
              {' '}
              +1 877-474-5006.
            </a>
            <br />
            <br />
            If you had given a different email than the one you just provided please try again. If
            your policy number doesn't start with the letters BAS you cannot use this system. In
            that case you have to use the alternate payment method below.
          </Label>
        </div>
      )}
      {isPasswordCreate && (
        <PasswordCreateForm
          isActivated={isActivated}
          setIsActivated={setIsActivated}
          type="creation"
        />
      )}
      {!isPasswordCreate && (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '2rem' }}>
          <fieldset disabled={isFormLoading}>
            <div className="form-group">
              <label htmlFor="contact_email" className="required">
                Contact Email
              </label>
              <input
                type="email"
                id="contact_email"
                name="contact_email"
                className="form-control"
                ref={register({ required: true })}
                required
              />
            </div>
            <div>
              <button
                type="submit"
                className="ttm-btn
            ttm-btn-size-xs
            ttm-btn-shape-square
            ttm-btn-style-fill
            ttm-btn-bgcolor-skincolor
            ttm-btn-color-white"
              >
                Submit
              </button>
            </div>
          </fieldset>
        </form>
      )}
      {isPasswordCreate && (
        <div className="mt-10">
          <a
            href="#"
            onClick={() => {
              setIsPasswordCreate(false);
              dispatch({
                type: '',
                data: {
                  contact_email: '',
                },
              });
            }}
          >
            Back
          </a>
        </div>
      )}
    </>
  );
};

export default FirstPagePayment;
