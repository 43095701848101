import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { resentActivationEmail, checkActivationCode } from '../../../api/client/api';

export interface ActivationFormProps {
  email: string;
  isActivated: boolean;
  type: string;
  handleActivate: (active: boolean) => void;
  setActivationCode: (code: string) => void;
}

const ActivationForm: React.FC<ActivationFormProps> = (props) => {
  const { email, isActivated, handleActivate, setActivationCode, type } = props;

  const {
    mutateAsync: sendActivationEmail,
    isLoading: isSendActivationEmailLoading,
  } = useMutation((data: any) => resentActivationEmail(data));

  const {
    mutateAsync: checkActivationCodeMutate,
    isLoading: isCheckActivationCodeLoading,
  } = useMutation((data: any) => checkActivationCode(data));

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: { contact_email: email, activation_code: '' },
  });

  const onActivationEmail = async () => {
    try {
      const response = await sendActivationEmail({
        contact_email: email,
        type,
      });
      if (response.success) {
        toast.info(
          'The activation code has been sent to your email. Please do not forget to check the spam folder.',
          {
            toastId: 'activation-email-success',
            closeButton: true,
            autoClose: false,
            position: 'bottom-center',
          },
        );
      }
    } catch (err) {
      if (err.response.status === 429) {
        toast.error('You already sent the maximum emails. Please try again in a few minutes.', {
          toastId: 'activation-email-send-error',
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      } else {
        toast.error('An error occurred. Please try again later. Thank you!', {
          toastId: 'activation-email-send-error',
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      }
    }
  };

  const onFirstSubmit = async (data) => {
    try {
      const response = await checkActivationCodeMutate({
        contact_email: email,
        activation_code: data.activation_code,
      });
      if (!response.activationCodeValid) {
        toast.error('Activation code was invalid. Please check your mail again.', {
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      } else {
        handleActivate(true);
        setActivationCode(data.activation_code);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      } else {
        toast.error('An error occurred. Please try again later. Thank you!', {
          toastId: 'activation-code-error',
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onFirstSubmit)} className="mt-4">
      <fieldset disabled={false}>
        <div className="form-group">
          <label htmlFor="contact_email" className="required">
            Contact Email
          </label>
          <input
            type="email"
            id="contact_email"
            name="contact_email"
            className="form-control"
            disabled
            ref={register({ required: true })}
            required
          />
        </div>
        {!isActivated && (
          <div className="form-group">
            <label htmlFor="activation_code" className="required">
              Activation Code
            </label>
            <input
              type="text"
              id="activation_code"
              name="activation_code"
              className={classNames('form-control', {
                'is-invalid': errors.activation_code,
              })}
              ref={register({
                required: true,
              })}
              required
            />
            <em>
              Please check your emails to find the activation code. Do not forget to check the spam
              folder as well. Click{' '}
              <button
                type="button"
                className="btn btn-link"
                onClick={onActivationEmail}
                style={{ padding: 0, textTransform: 'none', border: 0 }}
              >
                here
              </button>{' '}
              to send it again.
            </em>
            {errors.activation_code && (
              <div className="invalid-feedback">{errors.activation_code.message}</div>
            )}
          </div>
        )}
        {!isActivated && (
          <div>
            <button
              type="submit"
              className="ttm-btn
              ttm-btn-size-xs
              ttm-btn-shape-square
              ttm-btn-style-fill
              ttm-btn-bgcolor-skincolor
              ttm-btn-color-white"
            >
              Submit
            </button>
          </div>
        )}
      </fieldset>
    </form>
  );
};

export default ActivationForm;
