import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';

declare global {
  interface Window {
    google: any;
  }
}

export interface CustomGoogleMapProps {
  lat: number;
  lng: number;
}

const containerStyle = {
  width: '100%',
  height: '300px',
};

const CustomGoogleMap: React.FC<CustomGoogleMapProps> = (props) => {
  const { lat, lng } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds(
        { lat: lat - 0.001, lng: lng - 0.001 },
        { lat: lat + 0.001, lng: lng + 0.001 },
      );
      map.fitBounds(bounds);

      setMap(map);
    },
    [lat, lng],
  );

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat, lng }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <>
        <Marker position={{ lat, lng }} />
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default CustomGoogleMap;
