import { Fragment } from 'react';
import Loader from '../../../../layout/loader/Spinner';
import Label from '../../../../shared/components/label/Label';
import styles from '../InvoiceDataPagePayment.module.css';

const FormHiddenFields = (props) => {
  const {
    workingWithInvoices,
    invoicesToPay,
    userFullName,
    pLink,
    uName,
    uPass,
    selectedPolicyType,
    descriptorId,
    calculateTotalAmount,
    submitPayment,
  } = props;

  let returnObj: any;

  returnObj = invoicesToPay?.map((invoice, index) => {
    return (
      <Fragment
        key={invoice['_pk_Primary_id'] + invoice['c__Installment'] + invoice['c__PolicyNumber']}
      >
        <input type="hidden" name={`name_${index}`} id={`name_${index}`} value={userFullName} />
        <input
          type="hidden"
          name={`policy_number_${index}`}
          id={`policy_number_${index}`}
          value={invoice['c__PolicyNumber']}
        ></input>
        <input
          type="hidden"
          name={`da_${index}`}
          id={`payment_amount_${index}`}
          value={invoice['c__balanceDue']?.toFixed(2)}
        />
        <input
          type="hidden"
          name={`invoice_number_${index}`}
          id={`invoice_number_${index}`}
          value={invoice['d__InvoiceNumber']}
        />
      </Fragment>
    );
  });

  return workingWithInvoices ? (
    <Label style={styles.spacing} warning>
      <Loader />
    </Label>
  ) : (
    <Label style={styles.spacing} warning>
      {invoicesToPay.length === 0 && <strong>Select invoice to pay</strong>}
      {invoicesToPay.length > 0 && <strong>Amount to pay: ${calculateTotalAmount()}</strong>}
      <br />
      <br />
      {calculateTotalAmount() <= 0 && (
        <p>
          <em className="text-warning">
            The total amount should be a positive number to continue to your payment.
          </em>
        </p>
      )}
      <p>
        Please note: If paying by credit card there is a 3.25% service fee. There is NO fee if you
        pay by e-check (ACH).
      </p>
      <p>
        Default is credit card payment. If you wish to change please select ACH (E-check) on the
        next screen.
      </p>
      <div>
        <form id="paymentForm" className="d-none" method="POST" action={pLink}>
          <input type="hidden" name="app_user" id="app_user" value={uName} />
          <input type="hidden" name="app_pass" id="app_pass" value={uPass} />
          <input
            type="hidden"
            name="account_description_selection"
            id="account_description_selection"
            value={selectedPolicyType}
          />
          <input type="hidden" name="descriptor_id_0" id="descriptor_id_0" value={descriptorId} />
          <input type="hidden" name="nbr_policies" id="nbr_policies" value={invoicesToPay.length} />
          {returnObj}
        </form>
        {invoicesToPay
          .reduce((previous, current) => previous + current['c__balanceDue'], 0)
          .toFixed(2) > 0 && (
          <button
            onClick={submitPayment}
            className="ttm-btn
                    ttm-btn-size-xs
                    ttm-btn-shape-square
                    ttm-btn-style-fill
                    ttm-btn-bgcolor-skincolor
                    ttm-btn-color-white"
          >
            Submit
          </button>
        )}
      </div>
    </Label>
  );
};

export default FormHiddenFields;
