const Header: React.FC<any> = (props) => {
  const { subtitle } = props;

  return (
    <>
      <h2>Equine Payment Portal</h2>
      <p style={{ fontSize: '24px', marginBottom: '2rem' }}>{subtitle}</p>
    </>
  );
};

export default Header;
