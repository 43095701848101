import FooterItemContainer from '../footer-item-container/FooterItemContainer';
import officeImage from '../../../static/images/bascule//world-map-offices.png';
import { FaMapMarkerAlt } from 'react-icons/fa';

export interface OurOfficesItemProps {}

const OurOfficesItem: React.FC<OurOfficesItemProps> = (props) => {
  return (
    <FooterItemContainer label="Our Offices" className="widget flicker_widget clearfix">
      <div className="textwidget widget-text">
        <img src={officeImage} className="img-fluid" alt="map-footer"></img>
        <br />
        <br />
        <ul className="ttm-our-location-list">
          <li>
            <FaMapMarkerAlt color="#25c9d1" /> New Jersey, USA
          </li>
          <li>
            <FaMapMarkerAlt color="#25c9d1" /> London, UK
          </li>
        </ul>
      </div>
    </FooterItemContainer>
  );
};

export default OurOfficesItem;
