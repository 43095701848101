import { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { FaTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface FileInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  mode?: 'update' | 'append';
}

const FileInput: React.FC<FileInputProps> = (props) => {
  const { name, label = name, mode = 'update' } = props;

  const { register, unregister, setValue, watch } = useFormContext();
  const files: File[] = watch(name);
  const onDrop = useCallback(
    (droppedFiles) => {
      /*
         This is where the magic is happening.
         Depending upon the mode we are replacing old files with new one,
         or appending new files into the old ones, and also filtering out the duplicate files.
      */
      let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];
      if (mode === 'append') {
        newFiles = newFiles.reduce((prev, file) => {
          const fo = Object.entries(file);
          if (
            prev.find((e: File) => {
              const eo = Object.entries(e);

              return eo.every(
                ([key, value], index) => key === fo[index][0] && value === fo[index][1],
              );
            })
          ) {
            return prev;
          } else {
            return [...prev, file];
          }
        }, []);
      }
      // End Magic.
      setValue(name, newFiles, { shouldValidate: true });
    },
    [setValue, name, mode, files],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: props.accept,
    maxSize: 2 * 1000 * 1000, // max 2MB
  });

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const _onRemoveFile = (toBeRemovedFile) => {
    const newFiles = [...files];
    const indexToBeRemoved = newFiles.findIndex((file) => file.name === toBeRemovedFile.name);
    newFiles.splice(indexToBeRemoved, 1);

    setValue(name, newFiles, { shouldValidate: false });
  };

  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      fileRejections.forEach((fileRejected: any) => {
        const name = fileRejected.file.name;
        const error = fileRejected.errors?.[0];

        toast.error(`The file ${name} can not be attached: ${error.message}`, {
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      });
    }
  }, [fileRejections]);

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div {...getRootProps({ style })}>
        <input {...props} className="form-control" id={name} {...getInputProps()} />
        <div>
          <p className="text-center">Drop any files here...</p>
          <em>You can attach PDF, TXT, DOC, DOCX, PNG or JPG files.</em>
        </div>
      </div>
      <div>
        {!!files?.length && (
          <ul className="list-unstyled d-flex p-2 flex-column">
            {files.map((file) => {
              return (
                <li key={file.name} className="d-flex  flex-row">
                  <span>{file.name}</span>
                  <span className="ml-3">
                    <button
                      className="btn btn-link p-0"
                      aria-label="Remove file"
                      type="button"
                      onClick={() => _onRemoveFile(file)}
                    >
                      <FaTimesCircle color="#dc3545" size={15} />
                    </button>
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FileInput;
