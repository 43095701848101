import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import FileInput from './file-input/FileInput';
import { postClaimsForm } from '../../../api/client/api';
import ProtectedSubmit from '../../../shared/components/form/ProtectedSubmit/ProtectedSubmit';

const ClaimsForm = () => {
  const methods = useForm({
    mode: 'onBlur',
  });

  const recaptchaRef: any = useRef();

  const { mutateAsync: sendClaimsForm, isLoading: isClaimsLoading } = useMutation((formData) =>
    postClaimsForm(formData),
  );

  const onSubmit = async (data) => {
    try {
      await recaptchaRef.current.executeAsync();
      await sendClaimsForm(data);
      toast.success('Thank you for contacting us', { toastId: 'claims-success' });
      methods.reset();
      recaptchaRef.current.reset();
    } catch (error) {
      toast.error('An error occurred. Please try again later.', {
        toastId: 'claims-error',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  return (
    <div className="widget" style={{ flex: 1 }}>
      <h3 className="widget-title">Submit a claim online</h3>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="insured-name" className="required">
              Insured Name:
            </label>
            <input
              id="insured-name"
              name="insured-name"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="insured-phone-number" className="required">
              Insured phone number:
            </label>
            <input
              id="insured-phone-number"
              name="insured-phone-number"
              className="form-control"
              type="tel"
              ref={methods.register()}
            />
          </div>
          <div className="form-group">
            <label htmlFor="insured-email-address" className="required">
              Insured email address:
            </label>
            <input
              id="insured-email-address"
              name="insured-email-address"
              className="form-control"
              type="email"
              ref={methods.register({ required: true })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="policy-number" className="required">
              Policy Number:
            </label>
            <input
              id="policy-number"
              name="policy-number"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="name-of-horse" className="required">
              Name of horse:
            </label>
            <input
              id="name-of-horse"
              name="name-of-horse"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date-of-injury-illness" className="required">
              Date of injury/illness:
            </label>
            <input
              type="date"
              id="date-of-injury-illness"
              name="date-of-injury-illness"
              className="form-control"
              ref={methods.register({ required: true })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="nature-of-injury-illness" className="required">
              Nature of injury/illness:
            </label>
            <input
              id="nature-of-injury-illness"
              name="nature-of-injury-illness"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="location-of-horse" className="required">
              Location of horse:
            </label>
            <input
              id="location-of-horse"
              name="location-of-horse"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="attending-vet" className="required">
              Attending vet:
            </label>
            <input
              id="attending-vet"
              name="attending-vet"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="attending-vet-phone-number" className="required">
              Attending vet phone number:
            </label>
            <input
              id="attending-vet-phone-number"
              name="attending-vet-phone-number"
              className="form-control"
              ref={methods.register({ required: true })}
              type="text"
              required
            />
          </div>
          <div>
            <FileInput
              accept="image/png, image/jpg, image/jpeg, application/pdf, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
              name="files"
              label="Files"
              mode="append"
            />
          </div>
          <ProtectedSubmit isLoading={isClaimsLoading} ref={recaptchaRef} />
        </form>
      </FormProvider>
    </div>
  );
};

export default ClaimsForm;
