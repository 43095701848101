import useSiteDataQuery from '../../api/hooks/use-site-data-query/useSiteDataQuery';
import AboutOurBusinessItem from './about-our-business-item/AboutOurBusinessItem';
import LatestNewsItem from './latest-news-item/LatestNewsItem';
import LegalItem from './legal-item/LegalItem';
import OurOfficesItem from './our-offices-item/OurOfficesItem';
import QuickLinksItem from './quick-links-item/QuickLinksItem';

const Footer: React.FC = () => {
  const { data: { menuItems = [], latestNews = [], firstPageInfo = [] } = {} } = useSiteDataQuery({
    select: (data) => ({
      menuItems: data.menuItems,
      latestNews: data.latestNews,
      firstPageInfo: data.firstPageOptions,
    }),
  });

  const aboutUsFooter = firstPageInfo.find((info) => info.slug === 'ABOUT_US_FOOTER');

  return (
    <footer className="footer widget-footer clearfix">
      <div className="second-footer ttm-textcolor-white">
        <div className="container">
          <div className="row">
            <AboutOurBusinessItem title={aboutUsFooter?.option_name} text={aboutUsFooter?.value} />
            <QuickLinksItem menuItems={menuItems} />
            <LatestNewsItem latestNews={latestNews} />
            <OurOfficesItem />
          </div>
        </div>
      </div>
      <LegalItem />
    </footer>
  );
};

export default Footer;
