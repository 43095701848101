import { useContext, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { PaymentDispatchContext, PaymentStateContext } from '../context/PaymentContext';
import { postPaymentCreateUser, postPaymentLogin } from '../../../api/client/api';
import ActivationForm from './ActivationForm';

interface PasswordCreateFormProps {
  isActivated: boolean;
  setIsActivated: (active: boolean) => void;
  type?: string;
}

const PasswordCreateForm: React.FC<PasswordCreateFormProps> = (props) => {
  const { isActivated, setIsActivated, type = 'reset' } = props;

  const dispatch = useContext(PaymentDispatchContext);
  const paymentState = useContext(PaymentStateContext);
  const {
    mutateAsync: sendCreatePasswordForm,
    isLoading: isFormLoading,
  } = useMutation((formData: any) => postPaymentCreateUser(formData));

  const { mutateAsync: sendLoginForm, isLoading: isLogInLoading } = useMutation((data: any) =>
    postPaymentLogin(data),
  );

  const { register, handleSubmit, watch, setValue, trigger, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      contact_email: paymentState.data.contact_email,
      password: '',
      password_confirmation: '',
    },
  });
  const password = useRef({});
  password.current = watch('password', '');

  const [activationCode, setActivationCode] = useState('');

  const handleLogin = async (data) => {
    try {
      const response = await sendLoginForm({
        contact_email: data.contact_email,
        password: data.password?.trim(),
      });
      if (response.hasOwnProperty('success') && !response.success) {
        toast.error(
          response.message || 'The credentials you provided are not correct. Please try again.',
          {
            closeButton: true,
            autoClose: false,
            position: 'bottom-center',
          },
        );
      } else {
        sessionStorage.setItem('payment_user', JSON.stringify(response));
        dispatch({
          data: { reset: false },
          type: 'invoiceData',
        });
      }
    } catch (error) {
      toast.error('An error has occurred. Please try again later or contact with us.', {
        toastId: 'login-page-error',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await sendCreatePasswordForm({
        contact_email: paymentState.data.contact_email,
        newPassword: data.password,
        newPasswordConfirmation: data.password_confirmation,
        token: activationCode,
      });
      if (response.success) {
        toast.success(
          type === 'reset' ? 'You successfully reset your password.' : 'User created successfully',
          { toastId: 'password-create-success' },
        );
        await handleLogin({
          contact_email: paymentState.data.contact_email,
          password: data.password,
        });
      } else {
        toast.error(
          response.message ||
            'The activation code is invalid. Please check your emails or resend it.',
          {
            closeButton: true,
            autoClose: false,
            position: 'bottom-center',
          },
        );
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      } else {
        toast.error('An error occurred. Please try again later. Thank you!', {
          toastId: 'password-create-error',
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      }
    }
  };

  return (
    <>
      <ActivationForm
        email={paymentState.data.contact_email}
        type={type}
        isActivated={isActivated}
        handleActivate={setIsActivated}
        setActivationCode={setActivationCode}
      />
      {isActivated && (
        <form onSubmit={handleSubmit(onSubmit)} className="mt-1">
          <fieldset disabled={isFormLoading}>
            <div className="form-group">
              <label htmlFor="password" className="required">
                Please Create a NEW password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className={classNames('form-control', {
                  'is-invalid': errors.password,
                })}
                onChange={(e: any) => {
                  trigger('password_confirmation');
                  setValue('password', e.target.value, { shouldValidate: true });
                }}
                ref={register({
                  required: true,
                  pattern: {
                    value: /(?=.*\d)(?=.*[A-Z]).{10,}/,
                    message:
                      'Passwords must have a length of at least 10 characters, at least one number and contain at least one capital letter.',
                  },
                })}
                required
              />
              {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="password_confirmation" className="required">
                Retype NEW password
              </label>
              <input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                className={classNames('form-control', {
                  'is-invalid': errors.password_confirmation,
                })}
                ref={register({
                  required: true,
                  validate: (value) => {
                    return new Promise((resolve) => {
                      setTimeout(() => {
                        return resolve(value === password.current || 'The passwords do not match');
                      }, 0);
                    });
                  },
                })}
                required
              />
              {errors.password_confirmation && (
                <div className="invalid-feedback">{errors.password_confirmation.message}</div>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="ttm-btn
            ttm-btn-size-xs
            ttm-btn-shape-square
            ttm-btn-style-fill
            ttm-btn-bgcolor-skincolor
            ttm-btn-color-white"
              >
                Submit
              </button>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

export default PasswordCreateForm;
