import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import classNames from 'classnames';
import { parse, addMonths, isBefore } from 'date-fns';

import Header from '../components/header/Header';
import Label from '../../../shared/components/label/Label';
import { PaymentDispatchContext } from '../context/PaymentContext';

import styles from './InvoiceDataPagePayment.module.css';
import {
  getPaymentInvoiceData,
  postPaymentLogout,
  prepareInstallmentForPayment,
} from '../../../api/client/api';

import Spinner from '../../../layout/loader/Spinner';
import ExtraDetailsModal from './ExtraDetailsModal/ExtraDetailsModal';
import InvoiceDetails from './InvoiceDetails/InvoiceDetails';
import FormHiddenFields from './FormHiddenFields/FormHiddenFields';

const areWeTesting: string = 'production';

const pLink =
  areWeTesting === 'testing'
    ? 'https://test.simply-easier-payments.com/PaymentApp/Provider/insurance_multi_trans/fp/Payment.faces'
    : 'https://www.simply-easier-payments.com/PaymentApp/Provider/insurance_multi_trans/fp/Payment.faces';

const uName = areWeTesting === 'testing' ? 'KmJ3oUc0VXZ4jLFQezM5bQ==' : 'TStQZNzsed23aZY/Ozq8PQ==';

const uPass = areWeTesting === 'testing' ? 'fdasdf' : '594!69BB';

var selectedPolicyType = '';

const InvoiceDataPagePayment = () => {
  const dispatch = useContext(PaymentDispatchContext);

  const history = useHistory();

  const paymentUserJson = sessionStorage.getItem('payment_user');

  let paymentUser;
  try {
    paymentUser = JSON.parse(paymentUserJson || '');
  } catch (err) {}

  const userFullName = paymentUser.fullName;

  const {
    isLoading,
    isSuccess,
    data: { invoiceData, descriptorId } = { invoiceData: [] },
    error,
  } = useQuery<any, any>(
    'invoiceData',
    async () => {
      return await getPaymentInvoiceData({
        session_id: paymentUser.session_id,
      });
    },
    {
      retry: 2,
      onError: (err) => {
        if (!err.response) {
          return;
        }
        if (err.response.status === 500) {
          sessionStorage.removeItem('payment_user');
          history.replace('/', {
            emailValidationError: true,
          });
        } else {
          sessionStorage.removeItem('payment_user');
          toast.error(
            'Your session has been expired or you logged in from another browser/tab. Please login again.',
            {
              toastId: 'invoice-page-payment-error',
              closeButton: true,
              autoClose: false,
              position: 'bottom-center',
            },
          );
          dispatch({
            type: '',
            data: {
              contact_email: '',
            },
          });
        }
      },
    },
  );

  const [invoicesToPay, setInvoicesToPay] = useState<any[]>([]);

  const [invoiceObjectsToPay, setInvoiceObjects] = useState<any[]>([]);

  const [invoiceDetails, setInvoiceDetails] = useState<any>();

  const [shownHistories, setShownHistories] = useState<any[]>([]);

  const [workingWithInvoices, setWorkingWithInvoices] = useState<boolean>(false);

  const usePrepareInstallment = () => {
    const queryClient = useQueryClient();

    return useMutation(prepareInstallmentForPayment, {
      onSuccess: (data) => {
        setInvoicesToPay([...invoicesToPay, data.invoiceNumber]);
        queryClient.invalidateQueries('invoiceData');
        setWorkingWithInvoices(false);
      },
    });
  };

  const { mutate } = usePrepareInstallment();

  useEffect(() => {
    setWorkingWithInvoices(false);
    if (invoiceData.policyNumbers) {
      const preselectInvoices: any[] = [];
      const historiesToExpand: any[] = [];
      invoiceData?.policyNumbers?.forEach((policyNumber) => {
        invoiceData.allInvoices[policyNumber]
          ?.filter((invoice) => {
            return ['UnPaid', 'Partially Paid'].some((validStatus) => {
              return invoice['c__MasterStatus']?.toUpperCase() === validStatus.toUpperCase();
            });
          })
          ?.filter((invoice) => {
            const dueDate = parse(invoice['d__dueDate'], 'MM/dd/yyyy', new Date());
            const now = new Date();
            const nextMonth = addMonths(now, 1);

            return (
              (invoice['c__balanceDue'] < 0 ||
                isBefore(dueDate, now) ||
                isBefore(dueDate, nextMonth)) &&
              invoice['d__InvoiceNumber'] !== ''
            );
          })
          ?.forEach((invoice: any) => {
            preselectInvoices.push(invoice.d__InvoiceNumber);
            _updateSelectedAccount(policyNumber);
          });

        const unPaidInvoices = invoiceData.allInvoices[policyNumber]?.filter((invoice) => {
          return ['UnPaid', 'Partially Paid'].some((validStatus) => {
            return invoice['c__MasterStatus']?.toUpperCase() === validStatus.toUpperCase();
          });
        });

        if (unPaidInvoices.length === 0) {
          historiesToExpand.push(policyNumber);
        }
      });

      let invoicesSelectedBeforeRefresh = invoicesToPay?.filter((invoice) => {
        return !preselectInvoices.includes(invoice);
      });

      setInvoicesToPay([...preselectInvoices, ...invoicesSelectedBeforeRefresh]);
      setShownHistories([...historiesToExpand]);
    }
  }, [invoiceData.policyNumbers, invoiceData.allInvoices]);

  const _toggleInvoiceToPay = (newInvoice, policyNumber): boolean => {
    setWorkingWithInvoices(true);
    let index = -1;

    if (newInvoice['d__InvoiceNumber'] !== '') {
      index = invoicesToPay.findIndex((element) => element === newInvoice['d__InvoiceNumber']);
    }
    if (index >= 0) {
      //remove
      setInvoicesToPay([
        ...invoicesToPay.filter((invoice: any) => invoice !== newInvoice.d__InvoiceNumber),
      ]);
      if (!invoicesToPay.length) {
        selectedPolicyType = '';
      }
      setWorkingWithInvoices(false);

      return true;
    } else {
      //add
      if (newInvoice.d__InvoiceNumber === '') {
        mutate(newInvoice.zzz_installmentUUID);

        return false;
      } else {
        setInvoicesToPay([...invoicesToPay, newInvoice.d__InvoiceNumber]);
        _updateSelectedAccount(policyNumber);
        setWorkingWithInvoices(false);

        return true;
      }
    }
  };

  const _updateSelectedAccount = (policyNumber) => {
    const startingLetter = policyNumber.substring(0, 1);
    if (startingLetter === 'S') {
      selectedPolicyType = 'ACCEL';
    } else {
      selectedPolicyType = 'BAS';
    }
  };

  useEffect(() => {
    const invoices: any[] = [];

    if (invoicesToPay.length !== 0) {
      invoiceData?.policyNumbers?.forEach((policyNumber) => {
        invoiceData?.allInvoices[policyNumber]?.forEach((invoice) => {
          if (invoicesToPay.includes(invoice.d__InvoiceNumber)) invoices.push(invoice);
        });
      });

      setInvoiceObjects(invoices);
    }
  }, [invoicesToPay]);

  const _calculateTotalAmount = useCallback(() => {
    return invoiceObjectsToPay
      .reduce((previous, current) => previous + current['c__balanceDue'], 0)
      .toFixed(2);
  }, [invoiceObjectsToPay]);

  const _submitPayment = () => {
    const form: HTMLFormElement | null = document?.getElementById('paymentForm') as HTMLFormElement;

    if (invoicesToPay.length > 0 && _calculateTotalAmount() > 0) {
      form?.submit();
    }
    // if (invoicesToPay.length > 0 && _calculateTotalAmount() > 0) {
    //   let installmentsForHousekeeping: string[] = [];
    //   invoicesToPay.forEach((invoice) => {
    //     if (invoice.d__InvoiceNumber === '' || invoice._pk_Primary_id === '')
    //       installmentsForHousekeeping.push(invoice.zzz_installmentUUID);
    //   });

    //   const response = prepareInstallmentsForPayment(installmentsForHousekeeping);
    //   response.then((data) => {
    //     let newInvoicesToPay: any[] = [];
    //     for (let c = 0; c < invoicesToPay.length; c++) {
    //       newInvoicesToPay.push({ ...invoicesToPay });
    //       if (
    //         newInvoicesToPay[c].d__InvoiceNumber === '' ||
    //         newInvoicesToPay[c]._pk_Primary_id === ''
    //       ) {
    //         let dataItem = data[newInvoicesToPay[c].zzz_installmentUUID];
    //         newInvoicesToPay[c].d__InvoiceNumber = dataItem[1];
    //         newInvoicesToPay[c]._pk_Primary_id = dataItem[0];
    //         newInvoicesToPay[c].c__balanceDue = dataItem[2];
    //       }
    //     }
    //     setInvoicesToPay(newInvoicesToPay);
    //     //form?.submit();
    //   });
    // }
  };

  const _showModal = (invoice) => {
    setInvoiceDetails(invoice);
  };

  return (
    <div>
      <Header subtitle="Your payments history." />

      {isLoading && (
        <div className="mt-20">
          <Spinner />
        </div>
      )}
      {!isLoading && error && (
        <span>An error occurred fetching the payment invoiceData. Please try again later.</span>
      )}
      {!isLoading && isSuccess && (
        <>
          <Label style={styles.spacing} warning>
            Here are your active policies including their invoices, paid and upcoming. <br />
            <br />
            <strong>Amount to pay: ${_calculateTotalAmount()}</strong>
            <br />
            <p>
              Invoices currently due are selected.
              <br /> To amend the selection check or uncheck the boxes next to each invoice.
            </p>
          </Label>
          {invoiceData.policyNumbers.map((policyNumber) => (
            <Label style={styles.spacing} warning key={policyNumber}>
              <p className="uk-form-label">
                Policy: {policyNumber}
                <br />
                Horses: {invoiceData.horseNames[policyNumber].join(', ')}
              </p>
              <div>
                <h5>Invoices</h5>
                <table
                  className={`table table-striped table-responsive-sm ${styles.tableNoBorder}`}
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td>Invoice Number</td>
                      <td>Installment</td>
                      <td>Total Amount</td>
                      <td>Paid Amount</td>
                      <td>Amount to pay</td>
                      <td>Due Date</td>
                      <td className={styles.selectTd}>Select to pay</td>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.allInvoices[policyNumber]
                      .filter((invoice) =>
                        ['UnPaid', 'Partially Paid'].some((validStatus) => {
                          return (
                            invoice['c__MasterStatus']?.toUpperCase() === validStatus.toUpperCase()
                          );
                        }),
                      )
                      .sort(function (a, b) {
                        const z = a.d__dueDate.split('/');
                        const q = b.d__dueDate.split('/');
                        const dateA = z[2] + '-' + z[0] + '-' + z[1];
                        const dateB = q[2] + '-' + q[0] + '-' + q[1];

                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero. 1688083200000 1690675200000

                        return Date.parse(dateA) - Date.parse(dateB);
                      })
                      .map((invoice) => {
                        return (
                          <InvoiceDetails
                            key={
                              invoice['_pk_Primary_id'] +
                              invoice['c__Installment'] +
                              invoice['c__PolicyNumber']
                            }
                            invoice={invoice}
                            showInfo={_showModal}
                            invoicesToPay={invoicesToPay}
                            toggleInvoiceToPay={_toggleInvoiceToPay}
                            policyNumber={policyNumber}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div>
                <h5>Documents</h5>
                <table
                  className={`table table-striped table-responsive-sm ${styles.tableNoBorder}`}
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td>Type</td>
                      <td>Adjustment number</td>
                      <td>Date</td>
                      <td> </td>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.allDocs[policyNumber].map((doc) => {
                      return (
                        <tr key={doc['Invoice_15_REPORT_PolicyID::d__S3Url']}>
                          <td></td>
                          <td>{doc['Invoice_15_REPORT_PolicyID::dc__DocName']}</td>
                          <td>{doc['Invoice_15_REPORT_PolicyID::d__Stage']}</td>
                          <td>
                            {doc[
                              'Invoice_15.01_Report_ADJUSTMENT_AdjustmentID::d__adjustmentNumber'
                            ]
                              ? doc[
                                  'Invoice_15.01_Report_ADJUSTMENT_AdjustmentID::d__adjustmentNumber'
                                ]
                              : '-'}
                          </td>
                          <td>{doc['Invoice_15_REPORT_PolicyID::d__DateReport']}</td>
                          <td>
                            {' '}
                            <a
                              href={doc['Invoice_15_REPORT_PolicyID::d__S3Url']}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Download
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {invoiceData.allInvoices[policyNumber].filter((invoice) =>
                ['Paid'].some((validStatus) => {
                  return invoice['c__MasterStatus']?.toUpperCase() === validStatus.toUpperCase();
                }),
              ).length > 0 && (
                <div className="card">
                  <div className="card-header" id={`history-${policyNumber}`}>
                    <div>
                      <h5 className="mb-0">
                        <a
                          className={classNames('btn btn-link', styles.history)}
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => {
                            if (shownHistories.includes(policyNumber)) {
                              setShownHistories(
                                shownHistories.filter((item) => item !== policyNumber),
                              );
                            } else {
                              setShownHistories([...shownHistories, policyNumber]);
                            }
                          }}
                        >
                          History
                          {shownHistories.includes(policyNumber) ? <FaCaretUp /> : <FaCaretDown />}
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      className={classNames('collapse', {
                        hidden: !shownHistories.includes(policyNumber),
                        show: shownHistories.includes(policyNumber),
                      })}
                      aria-labelledby={`history-${policyNumber}`}
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <table
                          className={`table table-striped table-responsive-sm ${styles.tableNoBorder}`}
                        >
                          <thead>
                            <tr>
                              <td></td>
                              <td>Invoice Number</td>
                              <td>Installment</td>
                              <td>Total Amount</td>
                              <td>Paid Amount</td>
                            </tr>
                          </thead>
                          <tbody>
                            {invoiceData.allInvoices[policyNumber]
                              .filter((invoice) =>
                                ['Paid'].some((validStatus) => {
                                  return (
                                    invoice['c__MasterStatus']?.toUpperCase() ===
                                    validStatus.toUpperCase()
                                  );
                                }),
                              )
                              .map((invoice) => {
                                return (
                                  <InvoiceDetails
                                    key={
                                      invoice['_pk_Primary_id'] +
                                      invoice['c__Installment'] +
                                      invoice['c__PolicyNumber']
                                    }
                                    invoice={invoice}
                                    showInfo={_showModal}
                                    invoicesToPay={invoicesToPay}
                                    toggleInvoiceToPay={_toggleInvoiceToPay}
                                    policyNumber={policyNumber}
                                    prepInstallmentForPayment={mutate}
                                  />
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Label>
          ))}
          <FormHiddenFields
            workingWithInvoices={workingWithInvoices}
            invoicesToPay={invoiceObjectsToPay}
            userFullName={userFullName}
            uName={uName}
            uPass={uPass}
            pLink={pLink}
            descriptorId={descriptorId}
            selectedPolicyType={selectedPolicyType}
            calculateTotalAmount={_calculateTotalAmount}
            submitPayment={_submitPayment}
          />
        </>
      )}
      <div className={styles.spacing}>
        <a
          href="#"
          onClick={() => {
            postPaymentLogout({ session_id: paymentUser.session_id });
            sessionStorage.removeItem('payment_user');
            dispatch({
              data: {
                contact_email: '',
              },
              type: '',
            });
          }}
        >
          Log out
        </a>
        <span> - </span>
        <a
          href="#"
          onClick={() => {
            dispatch({
              data: {
                contact_email: '',
              },
              type: 'changePassword',
            });
          }}
        >
          Change Password
        </a>
      </div>
      <ExtraDetailsModal invoiceDetails={invoiceDetails} setInvoiceDetails={setInvoiceDetails} />
    </div>
  );
};

export default InvoiceDataPagePayment;
