import { TiHomeOutline } from 'react-icons/ti';

import PageTitle from '../../shared/components/page-title/PageTitle';
import contactUsImg from '../../static/images/bascule/contact/contact-usa.png';
import contactGbImg from '../../static/images/bascule/contact/contact-gb.png';
import LocationItem from './location-item/LocationItem';
import ContactForm from './contact-form/ContactForm';

import styles from './ContactUsTemplate.module.css';

export interface ContactUsTemplateProps {
  title: string;
}

const ContactUsTemplate: React.FC<ContactUsTemplateProps> = (props) => {
  const { title } = props;

  const locations = [
    {
      id: 1,
      imageSrc: contactUsImg,
      lat: 40.757119,
      lng: -73.986484,
      showMap: false,
      content: () => (
        <div>
          <p>
            Sarah Moore
            <br />
            <a href="mailto:sarah.moore@basculeuw.com">sarah.moore@basculeuw.com</a>
            <br />
            +1 877-474-5006
          </p>
          <p>
            <strong>Mailing address</strong>
            <br />
            PO Box 2502, Westfield, NJ 07090
          </p>
        </div>
      ),
    },
    {
      id: 2,
      imageSrc: contactGbImg,
      lat: 51.512346,
      lng: -0.075278,
      showMap: true,
      content: () => (
        <div>
          <p>
            London
            <br />
            St. Clare House,
            <br />
            30-33 Minories,
            <br />
            London EC3N 1PE
          </p>
          <p>
            William Hill
            <br />
            <a href="mailto:william.hill@basculeuw.com">william.hill@basculeuw.com</a>
            <br />
            Direct Line US:&nbsp;+1 646 793 3371
            <br />
            Direct Line UK:&nbsp;+44 (0)20 7977 9362
            <br />
            Mobile: +44 (0)7495 027 002
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title={title}
        path={{
          title: 'Home',
          Icon: TiHomeOutline,
          linksTo: '/',
          child: {
            title: title,
          },
        }}
      />
      <div className="site-main">
        <section className="ttm-row single-project-section clearfix">
          <div className="container">
            <div>
              {locations.map((location) => (
                <LocationItem
                  key={location.id}
                  lat={location.lat}
                  lng={location.lng}
                  imageSrc={location.imageSrc}
                  showMap={location.showMap}
                >
                  {location.content()}
                </LocationItem>
              ))}
            </div>
            <div className={styles.contactForm}>
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUsTemplate;
