import { useState } from 'react';

import usePostsQuery from '../../api/hooks/use-posts-query/usePostsQuery';
import ErrorTemplate from '../../templates/error/ErrorTemplate';
import NewsTemplate from '../../templates/news';

export interface NewsScreenProps {
  title?: string;
  subtitle?: string;
}

const defaultProps = {
  title: 'News',
  subtitle: 'The latest news and links to information and stories about Bascule',
};

const NewsScreen: React.FC<NewsScreenProps> = (props) => {
  const { title, subtitle } = props;

  const [page, setPage] = useState(1);

  const { data: posts, isSuccess, isError } = usePostsQuery(page);

  const _onPageChange = (pageIndex: number) => {
    setPage(pageIndex);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (isError) {
    return <ErrorTemplate />;
  }

  return isSuccess ? (
    <NewsTemplate
      title={title}
      subtitle={subtitle}
      posts={posts.data}
      totalPages={posts.last_page}
      onPageChange={_onPageChange}
    />
  ) : (
    <></>
  );
};

NewsScreen.defaultProps = defaultProps;

export default NewsScreen;
