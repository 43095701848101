import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import Header from '../components/header/Header';
import Label from '../../../shared/components/label/Label';
import { PaymentDispatchContext, PaymentStateContext } from '../context/PaymentContext';
import { postPaymentForgotPasswordMail } from '../../../api/client/api';
import PasswordCreateForm from '../password-creation/PasswordCreateForm';

const ForgotPasswordPagePayment = () => {
  const paymentState = useContext(PaymentStateContext);
  const dispatch = useContext(PaymentDispatchContext);

  const { mutate: sendResetPassword } = useMutation((data: any) =>
    postPaymentForgotPasswordMail(data),
  );

  useEffect(() => {
    sendResetPassword({
      contact_email: paymentState.data.contact_email,
    });
  }, []);
  const [isActivated, setIsActivated] = useState(false);

  return (
    <>
      <Header subtitle="Password reset" />
      <Label warning>
        {!isActivated && (
          <p>
            You requested to reset your password.
            <strong>
              {' '}
              Check your emails for the activation code. <br />
            </strong>
          </p>
        )}
        {isActivated && (
          <p>
            Please provide a strong password. <br />
            Passwords must have a length of at least 10 characters, at least one number and contain
            at least one capital letter.
            <br />
            Please don't use passwords that are easy to guess.
          </p>
        )}
      </Label>
      <PasswordCreateForm isActivated={isActivated} setIsActivated={setIsActivated} />
      <div style={{ marginTop: '2.5rem' }}>
        <a
          href="#"
          onClick={() =>
            dispatch({
              type: 'loginPage',
            })
          }
        >
          Go back
        </a>
      </div>
    </>
  );
};

export default ForgotPasswordPagePayment;
