import { FaInfoCircle } from 'react-icons/fa';
import Spinner from '../../../../layout/loader/Spinner';
import { useState } from 'react';
const InvoiceDetails: React.FC<any> = (props) => {
  const { invoice, showInfo, invoicesToPay, toggleInvoiceToPay, policyNumber } = props;
  const [isBusy, setIsBusy] = useState<boolean>(false);

  return (
    <tr>
      <td>
        <button className="btn btn-link" onClick={() => showInfo(invoice)}>
          <FaInfoCircle color="#0b0c26" />
        </button>
      </td>
      <td className="align-middle">{invoice['d__InvoiceNumber']}</td>
      <td className="align-middle">{invoice['c__Installment']}</td>
      <td className="align-middle">
        {'$ ' + (parseFloat(invoice['c__totalAmtIncTax']) || 0).toFixed(2)}
      </td>
      <td className="align-middle">
        {'$ ' + (parseFloat(invoice['c__totalPaymentReveived']) || 0).toFixed(2)}
      </td>
      {invoice['c__MasterStatus'].toUpperCase() !== 'PAID' && (
        <td className="align-middle">
          {'$ ' + (parseFloat(invoice['c__balanceDue']) || 0).toFixed(2)}
        </td>
      )}
      {invoice['c__MasterStatus'].toUpperCase() !== 'PAID' && (
        <td className="align-middle">{invoice['d__dueDate']}</td>
      )}
      {invoice['c__MasterStatus'].toUpperCase() !== 'PAID' && !isBusy && (
        <td className="align-middle">
          <input
            className="big-checkbox"
            type="checkbox"
            disabled={invoice['c__balanceDue'] < 0 && !isBusy}
            checked={invoicesToPay.includes(invoice['d__InvoiceNumber'])}
            onChange={() => {
              setIsBusy(true);
              if (toggleInvoiceToPay(invoice, policyNumber)) {
                setIsBusy(false);
              }
            }}
          />
        </td>
      )}
      {invoice['c__MasterStatus'].toUpperCase() !== 'PAID' && isBusy && (
        <td className="align-middle">
          <Spinner />
        </td>
      )}
    </tr>
  );
};

export default InvoiceDetails;
