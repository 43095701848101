import backendClient from './backend.client';
import paymentsClient from './payments.client';

export const fetchPostById = async (id: string) => {
  const response = await backendClient.get(`posts/id/${id}`);

  return response.data;
};

export const fetchPostBySlug = async (slug: string) => {
  const response = await backendClient.get(`posts/${slug}`);

  return response.data;
};

export const fetchPostsPaginated = async (page: number) => {
  const response = await backendClient.get(`/posts?page=${page}`);

  return response.data;
};

export const fetchSiteData = async () => {
  const response = await backendClient.get(`/data`);

  return response.data;
};

export const postContactForm = async (submitData) => {
  const response = await backendClient.post('/contactUsEmail', submitData);

  return response.data;
};

export const postClaimsForm = async (submitData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  Object.keys(submitData).forEach((key) => {
    if (key !== 'files') formData.append(key, submitData[key]);
  });

  submitData.files?.forEach((file) => {
    formData.append('files[]', file);
  });

  const response = await backendClient.post('/claimsEmail', formData, config);

  return response.data;
};

export const postPaymentCollectEmail = async (data) => {
  const response = await paymentsClient.post('/collectEmail', data);

  return response.data;
};

export const postPaymentCreateUser = async (data) => {
  const response = await paymentsClient.post('/createPassword', data);

  return response.data;
};

export const postPaymentLogin = async (data) => {
  const response = await paymentsClient.post('/login', data);

  return response.data;
};

export const postPaymentActivateEmail = async (data) => {
  const response = await paymentsClient.post('/activateEmail', data);

  return response.data;
};

export const postPaymentLogout = async (data) => {
  const response = await paymentsClient.post('/logout', data);

  return response.data;
};

export const postPaymentPasswordChange = async (data) => {
  const response = await paymentsClient.post('/passwordChange', data);

  return response.data;
};

export const postPaymentForgotPasswordMail = async (data) => {
  const response = await paymentsClient.post('/sendForgotPasswordEmail', data);

  return response.data;
};

export const postPaymentResetPasswordChange = async (data) => {
  const response = await paymentsClient.post('/resetPassword', null, { params: data });

  return response.data;
};

export const getPaymentInvoiceData = async (data) => {
  const response = await paymentsClient.post('/getInvoiceData', {
    session_id: data.session_id,
  });
  let responseSorted: any[] = [];

  response.data.invoiceData.policyNumbers.forEach((e) => {
    const unPaidInvoices = response.data.invoiceData.allInvoices[e].filter((p) => {
      return p.c__MasterStatus === 'UnPaid' || p.c__MasterStatus === 'Partially Paid';
    });
    if (unPaidInvoices.length > 0) {
      responseSorted.unshift(e);
    } else {
      responseSorted.push(e);
    }
  });

  response.data.invoiceData.policyNumbers = responseSorted;
  if (response.data.success) {
    return { invoiceData: response.data.invoiceData, descriptorId: response.data.descriptor_id };
  } else {
    return Promise.reject('Error');
  }
};

export const resentActivationEmail = async (data) => {
  const response = await paymentsClient.post('/resentActivationEmail', {
    contact_email: data.contact_email,
  });

  return response.data;
};

export const checkActivationCode = async (data) => {
  const response = await paymentsClient.post('/isActivationCodeValid', {
    contact_email: data.contact_email,
    activation_code: data.activation_code,
  });

  return response.data;
};

export const prepareInstallmentsForPayment = async (data) => {
  const response = await paymentsClient.post('/prepareInstallmentsForPayment', {
    installmentsForHouseKeeping: data,
  });

  return response.data;
};

export const prepareInstallmentForPayment = async (data) => {
  const response = await paymentsClient.post('/prepareInstallmentForPayment', {
    installmentForHouseKeeping: data,
  });

  return response.data;
};
