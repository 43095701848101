import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryUtils from 'query-string';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import FirstPagePayment from '../first-page/FirstPagePayment';
import ForgotPasswordPagePayment from '../forgot-password-page/ForgotPasswordPagePayment';
import InvoiceDataPagePayment from '../invoice-data-page/InvoiceDataPagePayment';
import LoginPagePayment from '../login-page/LoginPagePayment';
import PasswordChange from '../password-change-page/PasswordChangePagePayment';
import { postPaymentResetPasswordChange, postPaymentActivateEmail } from '../../../api/client/api';

export const PaymentStateContext = React.createContext<any>(undefined);
export const PaymentDispatchContext = React.createContext<any>(undefined);

const PaymentContextProvider: React.FC<any> = (props) => {
  const { initialType = '', initialData = {} } = props;

  const { mutate: sendPasswordEmail } = useMutation((data: any) =>
    postPaymentResetPasswordChange(data),
  );

  const { mutateAsync: sendActivationEmail } = useMutation((data: any) =>
    postPaymentActivateEmail(data),
  );

  const location = useLocation();

  const [paymentState, setPaymentState] = useState<any>({
    data: initialData,
    type: initialType,
  });

  const _updatePaymentState = (updatedState) =>
    setPaymentState({
      ...paymentState,
      data: {
        ...paymentState.data,
        ...updatedState.data,
      },
      type: updatedState.type,
    });

  useEffect(() => {
    const searchParams = queryUtils.parse(location.search);

    if (searchParams.reset_password && searchParams.contact_email) {
      window.history.replaceState(null, '', window.location.pathname);
      sendPasswordEmail({
        reset_password: searchParams.reset_password,
        contact_email: searchParams.contact_email,
      });
      _updatePaymentState({
        type: 'loginPage',
        data: {
          reset: true,
          resetToken: searchParams.reset_password,
          contact_email: searchParams.contact_email,
        },
      });
    }
  }, [location.search]);

  const _getPage = () => {
    switch (paymentState.type) {
      case 'loginPage':
        return <LoginPagePayment />;
      case 'forgotPassword':
        return <ForgotPasswordPagePayment />;
      case 'changePassword':
        return <PasswordChange />;
      case 'invoiceData':
        return <InvoiceDataPagePayment />;
      default:
        return <FirstPagePayment />;
    }
  };

  return (
    <PaymentStateContext.Provider value={paymentState}>
      <PaymentDispatchContext.Provider value={_updatePaymentState}>
        <div className="container">{_getPage()}</div>
      </PaymentDispatchContext.Provider>
    </PaymentStateContext.Provider>
  );
};

export default PaymentContextProvider;
