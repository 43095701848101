import { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Header from '../components/header/Header';
import Label from '../../../shared/components/label/Label';
import { PaymentDispatchContext, PaymentStateContext } from '../context/PaymentContext';
import { postPaymentLogout, postPaymentPasswordChange } from '../../../api/client/api';

const PasswordChangePagePayment = () => {
  const paymentUserJson = sessionStorage.getItem('payment_user');
  let paymentUser;
  try {
    paymentUser = JSON.parse(paymentUserJson || '');
  } catch (err) {}

  const { register, errors, handleSubmit, watch, trigger, setValue } = useForm({
    mode: 'onChange',
  });
  const new_password = useRef({});
  new_password.current = watch('new_password', '');
  const dispatch = useContext(PaymentDispatchContext);
  const paymentState = useContext(PaymentStateContext);

  const {
    mutateAsync: sendPasswordChangeForm,
    isLoading: isFormLoading,
  } = useMutation((formData: any) => postPaymentPasswordChange(formData));

  const onSubmit = async (data) => {
    try {
      const response = await sendPasswordChangeForm({
        session_id: paymentUser.session_id,
        oldPassword: data.old_password?.trim(),
        newPassword: data.new_password?.trim(),
        newPasswordConfirmation: data.new_password_confirmation?.trim(),
      });

      if (response.hasOwnProperty('success') && !response.success) {
        toast.error('The credentials you provided are not correct. Please try again.', {
          toastId: 'password-change-error-1',
          closeButton: true,
          autoClose: false,
          position: 'bottom-center',
        });
      } else {
        toast.success('You successfully changed your password.', {
          toastId: 'success-change-error',
        });
        dispatch({
          type: 'invoiceData',
        });
      }
    } catch (err) {
      toast.error('An error has occurred. Please try again later or contact with us.', {
        toastId: 'password-change-error-2',
        closeButton: true,
        autoClose: false,
        position: 'bottom-center',
      });
    }
  };

  return (
    <>
      <Header subtitle="Password Change." />
      <Label warning>
        Passwords must have a length of at least 10 characters, at least one number and contain at
        least one capital letter.
        <br />
        Please don't use passwords that are easy to guess.
      </Label>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '2rem' }}>
        <fieldset disabled={isFormLoading}>
          <div className="form-group">
            <label htmlFor="old_password" className="required">
              OLD Password
            </label>
            <input
              type="password"
              id="old_password"
              name="old_password"
              className="form-control"
              ref={register({ required: true })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="new_password" className="required">
              Please Create a NEW password
            </label>
            <input
              type="password"
              id="new_password"
              name="new_password"
              className={classNames('form-control', {
                'is-invalid': errors.new_password,
              })}
              onChange={(e: any) => {
                trigger('new_password_confirmation');
                setValue('new_password', e.target.value, { shouldValidate: true });
              }}
              ref={register({
                required: true,
                pattern: {
                  value: /(?=.*\d)(?=.*[A-Z]).{10,}/,
                  message:
                    'Passwords must have a length of at least 10 characters, at least one number and contain at least one capital letter.',
                },
              })}
              required
            />
            {errors.new_password && (
              <div className="invalid-feedback">{errors.new_password.message}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="new_password_confirmation" className="required">
              Retype NEW password
            </label>
            <input
              type="password"
              id="new_password_confirmation"
              name="new_password_confirmation"
              className={classNames('form-control', {
                'is-invalid': errors.new_password_confirmation,
              })}
              ref={register({
                required: true,
                validate: (value) => {
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      return resolve(
                        value === new_password.current || 'The passwords do not match',
                      );
                    }, 0);
                  });
                },
              })}
              required
            />
            {errors.new_password_confirmation && (
              <div className="invalid-feedback">{errors.new_password_confirmation.message}</div>
            )}
          </div>
        </fieldset>
        <div>
          <button
            type="submit"
            disabled={isFormLoading}
            className="ttm-btn
            ttm-btn-size-xs
            ttm-btn-shape-square
            ttm-btn-style-fill
            ttm-btn-bgcolor-skincolor
            ttm-btn-color-white"
          >
            Submit
          </button>
        </div>
      </form>
      <br />
      <a
        href="#"
        onClick={() => {
          dispatch({
            type: 'invoiceData',
          });
        }}
      >
        Go Back
      </a>
      <span> - </span>
      <a
        href="#"
        onClick={() => {
          postPaymentLogout({ session_id: paymentUser.session_id });
          sessionStorage.removeItem('payment_user');
          dispatch({
            data: {
              contact_email: '',
            },
            type: '',
          });
        }}
      >
        Log Out
      </a>
    </>
  );
};

export default PasswordChangePagePayment;
