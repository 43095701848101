import PaymentContextProvider from './context/PaymentContext';
import Label from '../../shared/components/label/Label';

const UserPaymentScreen = () => {
  const paymentUserJson = sessionStorage.getItem('payment_user');

  let paymentUser;
  try {
    paymentUser = JSON.parse(paymentUserJson || '');
  } catch (err) {}

  return (
    <div className="site-main ttm-page-title-row-bascule fixMobile">
      <section className="ttm-row single-project-section clearfix">
        <PaymentContextProvider
          initialType={paymentUser ? 'invoiceData' : ''}
          initialData={paymentUser ? { ...paymentUser } : {}}
        ></PaymentContextProvider>
      </section>
    </div>
  );
};

export default UserPaymentScreen;
